export default ` <h2> אומנות הויכוח</h2>
<p>
    היכולת לקחת חלק בדיון היא קריטית היום יותר מתמיד. עם
    הכלים הנכונים אדם יכול להביא את השקפת עולמו בצורה
    אפקטיבית יותר, להצליח לגעת ביותר אנשים ולעצב מציאות
    נכונה יותר.
</p>

<p>
    אם מעניין אותך לקח חלק בשיעורים בנושא השאר/י פרטים
    ונחזור אלייך. את השיחה נתחיל ב
    <a
        href="https://www.youtube.com/watch?v=ohDB5gbtaEQ"
        target="_blank"
        rel="noopener noreferrer">
        ויכוח
    </a>
    כדי לראות באיזו רמה את/ה*.
</p>`;
