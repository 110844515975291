import dayjs from "dayjs";

const randomNumber = maxPower => {
    const pow = Math.floor(Math.random() * maxPower);
    const multi = Math.pow(10, pow);
    const number = Math.floor(Math.random() * 10) * multi;

    if (number > 1000) {
        return Math.floor(number / 1000) + "K";
    }

    return number.toLocaleString();
};

const randomSocial = maxPower => {
    return {
        likes: randomNumber(maxPower),
        comments: randomNumber(maxPower / 2),
        shares: randomNumber(maxPower / 2),
    };
};

const defaults = () => {
    const r1 = randomSocial(4);
    const r2 = randomSocial(1.3);

    return {
        stats: {
            durationAnalyze: 0,
            durationVisit: 0,
            inbounds: 0,
            visits: 0,
        },
        progress: {
            doneArguments: false,
            doneLofs: false,
            doneSubtitles: false,
            isDeleted: false,
            isPromoted: false,
            isPublished: false,
            snoozeUntil: "",
            dateImported: "",
            parsedFields: 0,
            datePublished: "",
        },
        info: {
            index: 1,
            layer: "lofs",
            media: "text",
            size: "single",
            source: "facebook",
            flavour: "tagging",
            postId: "",
            fbUserName: "",
            postUrl: "",
            profileUrl: "",
        },
        root: {
            id: "i0",
            cardTopic: "",
            authorName: "Eli Jones",
            content: "",
            authorImageUrl: "https://randomuser.me/api/portraits/women/14.jpg",
            authorProfileUrl: "",
            dateDebate: dayjs().unixFull(),
            lofs: 0,
            personaId: "",
            imageUrl: "",
            imageRatio: 1,
            videoUrl: "",
            videoId: "",
            originComments: r1.comments,
            originLikes: r1.likes,
            originShares: r1.shares,
            cardImageUrl: "",
            cardImageRatio: 1,
            participantId: "i1",
        },
        comment: {
            content: "",
            id: "i1",
            order: 1,
            originLikes: r2.likes,
            timeComment: dayjs().unixFull(),
            participantId: "i1",
        },
        participant: {
            id: "i1",
            imageUrl: "",
            title: "",
        },
        participants: {
            i1: {
                id: "i1",
                imageUrl: "https://randomuser.me/api/portraits/women/14.jpg",
                title: "Eli Jones",
            },
            i10: {
                id: "i10",
                imageUrl: "https://randomuser.me/api/portraits/men/57.jpg",
                title: "Chelsea Hendricks",
            },
            i11: {
                id: "i11",
                imageUrl: "https://randomuser.me/api/portraits/men/72.jpg",
                title: "Flynn Elliott",
            },
            i12: {
                id: "i12",
                imageUrl: "https://randomuser.me/api/portraits/women/84.jpg",
                title: "Jaylin Connor",
            },
            i13: {
                id: "i13",
                imageUrl: "https://randomuser.me/api/portraits/men/58.jpg",
                title: "Enrique Rodriguez",
            },
            i14: {
                id: "i14",
                imageUrl: "https://randomuser.me/api/portraits/women/17.jpg",
                title: "Wade McLamb",
            },
            i15: {
                id: "i15",
                imageUrl: "https://randomuser.me/api/portraits/women/16.jpg",
                title: "Deacon Wright",
            },
            i16: {
                id: "i16",
                imageUrl: "https://randomuser.me/api/portraits/women/40.jpg",
                title: "Kendyl Henson",
            },
            i17: {
                id: "i17",
                imageUrl: "https://randomuser.me/api/portraits/men/48.jpg",
                title: "Ibrahim Law",
            },
            i2: {
                id: "i2",
                imageUrl: "https://randomuser.me/api/portraits/women/83.jpg",
                title: "Hallie Schultz",
            },
            i3: {
                id: "i3",
                imageUrl: "https://randomuser.me/api/portraits/men/3.jpg",
                title: "Dominique Massey",
            },
            i4: {
                id: "i4",
                imageUrl: "https://randomuser.me/api/portraits/women/72.jpg",
                title: "Kyleigh Fleming",
            },
            i5: {
                id: "i5",
                imageUrl: "https://randomuser.me/api/portraits/women/30.jpg",
                title: "Kendra Lindsay",
            },
            i6: {
                id: "i6",
                imageUrl: "https://randomuser.me/api/portraits/women/43.jpg",
                title: "Kyla Duke",
            },
            i7: {
                id: "i7",
                imageUrl: "https://randomuser.me/api/portraits/men/40.jpg",
                title: "Jenna Coble",
            },
            i8: {
                id: "i8",
                imageUrl: "https://randomuser.me/api/portraits/women/81.jpg",
                title: "Matilda Perkins",
            },
            i9: {
                id: "i9",
                imageUrl: "https://randomuser.me/api/portraits/women/90.jpg",
                title: "Roger Hampton",
            },
            i18: {
                id: "i18",
                imageUrl: "https://randomuser.me/api/portraits/women/80.jpg",
                title: "Jaxton Merrill",
            },
            i19: {
                id: "i19",
                imageUrl: "https://randomuser.me/api/portraits/women/85.jpg",
                title: "Bryan Daniel",
            },
            i20: {
                id: "i20",
                imageUrl: "https://randomuser.me/api/portraits/men/99.jpg",
                title: "Aubrianna Sutton",
            },
            i21: {
                id: "i21",
                imageUrl: "https://randomuser.me/api/portraits/men/4.jpg",
                title: "Marquis Simpson",
            },
            i22: {
                id: "i22",
                imageUrl: "https://randomuser.me/api/portraits/women/49.jpg",
                title: "Yousef Warner",
            },
        },
    };
};

const defaultDebate = () => {
    const data = defaults();

    return {
        bucketId: 1,
        stats: { ...data.stats },
        progress: { ...data.progress },
        info: { ...data.info },
        root: { ...data.root },
        comments: {},
        participants: { ...data.participants },
    };
};

const defaultComment = () => {
    const data = defaults();

    return data.comment;
};

const defaultTag = {
    parentId: "-LgKxj06PThiIMDCqh3Q", // "the others"
    title: "",
    titleEn: "",
    titleOrg: "",
    description: "",
    doneCard: false,
    doneWiki: false,
    examplesCount: 0,
    href: "",
    hrefEn: "",
    hideInSelect: false,
};

const defaultExample = {
    description: "",
};

const defaultPersona = {
    title: "",
    facebookUrl: "",
    imageUrl: "",
    instagramUrl: "",
    wikiUrl: "",
    twitterUrl: "",
};

const defaultBlogPost = () => ({
    progress: {
        doneWriting: false,
        isDeleted: false,
        isPublished: false,
        datePublished: dayjs().unixFull(),
    },
    card: {
        title: "",
        subtitle: "",
        dateArticle: dayjs().unix(),
        imageUrl: "",
        readingTimeInMinutes: "5",
        authorName: "מערכת DebDB",
    },
    elements: {
        i1: {
            id: "i1",
            type: "header",
            content: "",
            order: 1,
            data: {
                title: "כותרת הבלוג",
                subtitle:
                    "כל מה שרצית לשאול על כשלים לוגים ולא היה לך את האומץ לשאול",
            },
        },
        i2: {
            id: "i2",
            type: "author",
            content: "",
            order: 2,
            data: {
                title: "מערכת DebDB",
                imageUrl: "https://il.debdb.com/fav/favicon-128.png",
                date: "2019-10-10",
                readTimeInMinutes: "5",
            },
        },
        i3: {
            id: "i3",
            type: "paragraph",
            content: "תוכן הפסקה",
            order: 3,
            data: {},
        },
    },
});

const defaultBlogElements = {
    paragraph: {
        type: "paragraph",
        content: "תוכן הפסקה",
    },
    h: {
        type: "h",
        content: "כותרת",
        data: {
            size: 2,
        },
    },
    quote: {
        type: "quote",
        content: "תוכן הציטוט",
        data: {},
    },
    author: {
        type: "author",
        content: "",
        data: {
            title: "מערכת DebDB",
            imageUrl: "https://il.debdb.com/fav/favicon-128.png",
            date: "2019-10-10",
            readTimeInMinutes: "5",
        },
    },
    image: {
        type: "image",
        content: "",
        data: {
            caption: "תיאור התמונה",
            url:
                "https://imgplaceholder.com/600x200/eeeeee/aaaaaa/fa-cloud-upload?font-size=30",
            width: 600,
        },
    },
    lofDefinition: {
        type: "lofDefinition",
        content: "",
        data: {},
    },
    root: {
        type: "root",
        content: "",
        data: {
            debateId: null,
        },
    },
    wikiLink: {
        type: "wikiLink",
        content: "לינק לערך של הכשל",
        data: {
            tagId: null,
        },
    },
    debateLink: {
        type: "debateLink",
        content: "לינק לדיון",
        data: {
            debateId: null,
        },
    },
    beforeAfter: {
        type: "beforeAfter",
        content: "",
        data: {},
    },
    examples: {
        type: "examples",
        content: "",
        data: {},
    },
    video: {
        type: "video",
        content: "",
        data: {
            caption: "תיאור הוידאו",
            url: "https://www.youtube.com/watch?v=fk95HxR9EZQ",
            width: 300,
        },
    },
};

export default {
    defaults,
    defaultDebate,
    defaultComment,
    defaultTag,
    defaultExample,
    defaultPersona,
    defaultBlogElements,
    defaultBlogPost,
};
