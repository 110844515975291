export default ` <h2>אבני דרך</h2>

<h3>הגדרות</h3>
<ul>
    <li>
        <strong>תייגנ/ית:</strong> מישהו/י שמתייגת כשללים
        בדיונים
    </li>
    <li>
        <strong>יבואנ/ית:</strong> מישהו/י שמייבא/ת דיונים
        מפייסבוק
    </li>
    <li>
        <strong>שלטים:</strong> לינק של deb שפורסם בדיון
        בפייסבוק
    </li>
    <li>
        <strong>count:</strong> מספר הדיונים במערכת
    </li>
    <li>
        <strong>ביקורים:</strong> מספר הסשנים במערכת
    </li>
    <li>
        <strong>תיוגים:</strong> מספר התיוגים במערכת
    </li>
    <li>
        <strong>flavour:</strong> סוג הדיון: יחיד, וידאו או דיון
    </li>
    <li>
        <strong>צפיות:</strong> מספר הצפיות בדיונים
    </li>
    <li>
        <strong>זמן ביקור:</strong> זמן סשן ממוצע בקרב סשנים עם
        10 שניות ומעלה
    </li>
    <li>
        <strong>swipes:</strong> מספר צפיות בפוסטים פר סשן
    </li>
    <li>
        <strong>אפוי:</strong> דיון שעבר תיוג מלא
    </li>
    <li>
        <strong>חצי-אפוי:</strong> דיון שעבר תיוג חלקי
    </li>
    <li>
        <strong>בהמתנה:</strong> דיון שממתין לתיוג
    </li>
</ul>
<h3>יוני 2019</h3>
<h4>ראשיים KPIs</h4>
<ul>
    <li>
        <strong>מספר מתייגים:</strong> 10
    </li>
    <li>
        <strong>מספר שלטים:</strong> 200
    </li>
    <li>
        <strong>ביקורים יומיים:</strong> 300
    </li>
    <li>
        <strong>זמן ביקור:</strong> 60s
    </li>
</ul>
<h4>תוכנית</h4>
<ul>
    <li>מתייגים בתשלום</li>
    <li>לימוד הכשללים</li>
    <li>התנסות עם flavours</li>
</ul>`;
