export default `<h2>מתנדבים</h2>
<p>
    תרבות הדיון באינטרנט מעניינת אותך? תמיד עניין אותך איך לנסח
    דברים בצורה הטובה ביותר? רוצה לקחת חלק גדול או קטן בעיצוב
    השיח בפייסבוק בפרט ובאינטרנט בכלל? מעולה, אולי תרצה/י להתנדב
    איתנו, אנחנו מחפשים:
</p>
<h3>על הדרך</h3>
<ul>
    <li>
        <strong>תייגים:</strong>
        אנשים שבא להם לתייג את debdb בכל דיון מעניין שהם נתקלים
        בו
    </li>
    <li>
        <strong>מדגימים: </strong>
        אנשים בא להם להוסיף דוגמאות לכשלים לוגיים לאנציקלופדיית
        הכשלים שלנו
    </li>
    <li>
        <strong>מפיצים:</strong>
        אנשים בא להם להפיץ את debdb ברחוב וברשת
    </li>
</ul>
<h3>בינוני</h3>
<ul>
    <li>
        <strong>יבואנים:</strong>
        אנשים שבא להם להתקין את תוסף debdb וייבאו דיונים באופן
        פעיל
    </li>
    <li>
        <strong>מנתחים:</strong>
        אנשים שבא להם לתייג כשלים לוגיים בדיונים קיימים בזמנם
        הפנוי
    </li>
    <li>
        <strong>קשובים:</strong>
        אנשים עם היכרות עם תקשורת מקרבת שבא להם לתייג צרכים שלא
        נענו
    </li>
</ul>
<h3>לארג׳</h3>
<ul>
    <li>
        <strong>רעיונאים:</strong>
        אנשים שבא להם לבוא לישיבות ולהציע רעיונות
    </li>
    <li>
        <strong>תכנתים:</strong>אנשים שבא להם לכתוב קצת מהקוד של
        debdb
    </li>
</ul>
<br />
<p>אם אחד מאלה נראה לך מעניין, קח/י יוזמה:</p>`;
